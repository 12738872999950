import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout";
import { Container, Form, Button } from 'react-bootstrap';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("mvoljkng"); // ??
  if (state.succeeded) {
    return <h3>Thanks for contact</h3>;
  }
  return (
    <Form onSubmit={handleSubmit} className="contactForm">
      <Form.Group className="mb-3 inputLabel" controlId="name" >
        <Form.Label><strong>Full Name *</strong></Form.Label>
        <Form.Control type="text" className="formInput px-0" required name="name"
        />
      </Form.Group>
      <Form.Group className="mb-3 inputLabel" controlId="company" >
        <Form.Label><strong>Company</strong></Form.Label>
        <Form.Control type="text" className="formInput px-0" name="company" />
        <ValidationError
          prefix="company"
          field="company"
          errors={state.errors}
        />
      </Form.Group>
      <Form.Group className="mb-3 inputLabel" controlId="email" >
        <Form.Label><strong>Email *</strong></Form.Label>
        <Form.Control type="text" className="formInput px-0" required name="email" />
        <ValidationError
          prefix="email"
          field="email"
          errors={state.errors}
        />
      </Form.Group>
      <Form.Group className="mb-3 inputLabel" controlId="tel" >
        <Form.Label><strong>Tel *</strong></Form.Label>
        <Form.Control type="text" className="formInput px-0" required name="tel" />
        <ValidationError
          prefix="tel"
          field="tel"
          errors={state.errors}
        />
      </Form.Group>
      <Form.Group className="mb-3 inputLabel" controlId="message" >
        <Form.Label><strong>Comments</strong></Form.Label>
        <Form.Control as="textarea" type="text" className="formInput px-0" name="message" />
        <ValidationError
          prefix="message"
          field="message"
          errors={state.errors}
        />
      </Form.Group>
      <Button variant="link" type="submit" disabled={state.submitting} className="contact-submit-btn d-flex align-items-center bg-light text-dark border-none px-0 py-3 my-3">
        <strong className="pe-2">SUBMIT YOUR REQUEST</strong>
        <StaticImage src="../images/shapes/rightArrow.svg" alt="link arrow" className="contact-submit-arrow" />
      </Button>
    </Form>
  );
}

const Contact = () => {
  return (
    <Layout>
      <Container fluid className="contact-page px-3 px-md-5 pt-5" action="mailto:ka@think-plus.gr">
        <h1 className="my-4">Submit : Your inquiry</h1>
        <div className="d-flex flex-column flex-sm-row justify-content-between">
          <ContactForm />
          <div className="contactDetails d-flex flex-column align-items-center mt-4">
            <div className="d-flex flex-column mb-4">
              <span>Kifisias 23, 14561</span>
              <span>Athens, Greece</span>
              <span>+306944578173</span>
              <span>info@arche.gr</span>
            </div>
            <div className="d-flex flex-column mb-4">
              <span>Kifisias 23, 14561</span>
              <span>Athens, Greece</span>
              <span>+306944578173</span>
              <span>info@arche.gr</span>
            </div>
            <div className="d-flex flex-column mb-4">
              <span>Kifisias 23, 14561</span>
              <span>Athens, Greece</span>
              <span>+306944578173</span>
              <span>info@arche.gr</span>
            </div>
          </div>
        </div>
      </Container>
    </Layout>

  )
}

export default Contact;